<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
                <div style=" display: flex; align-items: right; justify-content: right;">
                    <Button label="Guardar" icon="pi pi-check" class="p-button-secondary mb-2 " @click="Guardar()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
                </div>

                <Accordion :activeIndex="0">
                    <AccordionTab :header="notif.nombre" v-for="(notif, index) in products" :key="index">
                        <InputText id="name" v-model="notif.nombre" required="true"/>
                        <Editor v-model="notif.texto" editorStyle="height: 320px" />
                    </AccordionTab>
                </Accordion>


			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Notificacion');

	export default {
		data() {
			return {
				nuevo: false,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				permisos: {},
				niveles: null,
				productDialog: false,
				permisosDialog: false,
				deleteDialog: false,
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {	
				Consulta.Ini().then(response => {
					//this.$store.state.error = response.result;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result;
					this.$store.commit('Loading');
				}); 
			},
			Guardar() {
				Consulta.Procesar('UPD',{
						product: this.products,
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.Mostrar();
						}
					});
				
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
